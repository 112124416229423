require("regenerator-runtime/runtime");

const $ = require('./plugins/jquery');
$.select2 = require('./plugins/select2')();
window.$ = window.jQuery = $;

require('./plugins/modernizr');
require('./plugins/popper');
require('./plugins/bootstrap');
require('./plugins/bootstrap-select');
require('./plugins/slick');
require('./plugins/jquery.countdown');
require('./plugins/jquery.magnific-popup');
require('./plugins/jquery-ui');
require('./plugins/jquery.mask.min');
require('./plugins/jgrowl');

require('./main');

require('./plugins/ms2.js');
require('./plugins/fotorama');
require('./new');