const fotoramaDefaults = {
    nav: "thumbs",
    width: "100%",
    height: 600,
    ratio: 0,
    fit: "contain",
    thumbfit: "contain",
    thumbheight: 85,
    thumbwidth: 85,
    allowfullscreen: true,
    swipe: true,
    autoplay: 5000
};

/**
 * @typedef {Object} ApiResponse
 * @property {'success'|'error'} status
 * @property {String} message
 * @property {Object} data
 */

window.getCookie = function (name) {
    let matches = document.cookie.match(new RegExp(
        "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
}

window.setCookie = function (name, value, options = {}) {
    let domain = location.hostname.split('.');
    if (domain.length > 2) domain.shift();
    domain = domain.join('.');
    options = {
        path: '/',
        domain: domain,
        ...options
    };

    if (options.expires instanceof Date) {
        options.expires = options.expires.toUTCString();
    }

    let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);

    for (let optionKey in options) {
        updatedCookie += "; " + optionKey;
        let optionValue = options[optionKey];
        if (optionValue !== true) {
            updatedCookie += "=" + optionValue;
        }
    }

    document.cookie = updatedCookie;
}

window.deleteCookie = function (name) {
    setCookie(name, "", {
        'max-age': -1
    })
}

/**
 * @param {String} action
 * @param {String} method
 * @param {Object} data
 *
 * @return ApiResponse
 */
async function apiFetch(action, method, data = {}) {
    let response = await fetch(`/api/${action}/${method}/`, {
        method: 'POST',
        body: JSON.stringify(data)
    });

    try {
        response = response.json();
    } catch (err) {
        console.error(err);
        return;
    }

    return response;
}

function notice(status = 'error', message = null, life = 3500) {
    message = message || 'Произошла ошибка, перезагрузите страницу и повторите попытку';
    let icon;
    switch (status) {
        case "success":
            icon = 'check';
            break;
        case "info":
            icon = 'info';
            break;
        default:
            icon = 'exclamation-triangle';
            break;
    }
    message = `
        <div class="message__icon"><i class="fa fa-${icon}"></i></div>
        <div class="message__content">${message}</div>
    `;
    $.jGrowl(message, {theme: `notify__theme--${status}`, life: life});
}

function initCountdown() {
    $('[data-countdown]').each(function () {
        var $this = $(this), finalDate = $(this).data('countdown');
        $this.countdown(finalDate, function (event) {
            $this.html(event.strftime('<div class="countdown-items"><div class="countdown-col"><div class="single-countdown-item item-bg"><span class="count">%D</span><span class="value">Дней</span></div></div><div class="countdown-col"><div class="single-countdown-item"><span class="count">%H</span><span class="value">Часов</span></div></div><div class="countdown-col"><div class="single-countdown-item"><span class="count">%M</span><span class="value">Минут</span></div></div><div class="countdown-col"><div class="single-countdown-item"><span class="count">%S</span><span class="value">Секунд</span></div></div></div>'));
        });
    });
}

//miniCart
(function ($) {
    //region dropdown
    const cartDropdown = '.cart-dropdown';
    const dropdownMenu = `${cartDropdown} .dropdown-menu`;

    let cartInnerClose;

    $(document).on('mouseleave', cartDropdown, function () {
        cartInnerClose = cartInnerClose = setTimeout(function () {
            $(dropdownMenu).stop(true, true).slideUp(500);
            $(cartDropdown).removeClass('open');
        }, 350);
    });
    $(document).on('mouseenter', cartDropdown, function () {
        clearTimeout(cartInnerClose);
        $(dropdownMenu).stop(true, true).slideDown(500);
        $(cartDropdown).addClass('open');
    });
    $(document).on('click', 'button[value="cart/add"]', function (event) {
        const id = $(event.target).data('id'); 
        getProductPreview(id, 'productAdded'); 
    });  
    //endregion
 
    //region event on add/remove/change/clean product
    const cartDesktop = '.miniCart--desktop';
    const cartMobile = '.miniCart--mobile';
    const itemClass = '.single-mini-cart';
    const removeElementClass = '.miniCart-item-remove--js';

    const updateDesktop = async function () {
        let response = await apiFetch('Cart', 'getMiniCart');
        if (response.status !== 'success') return;
        let opened = $(cartDropdown).hasClass('open');

        $(cartDesktop).replaceWith(response.data.cart);
        if (opened) {
            $(cartDropdown).addClass('open');
            $(cartDropdown).stop(true, true).slideDown(0);
        }
    };

    const updateMobile = async function () {
        let response = await apiFetch('Cart', 'getMiniCart', {type: 'mobile'});
        if (response.status !== 'success') return;

        $(cartMobile).replaceWith(response.data.cart);
    };

    const update = () => {
        updateDesktop();
        updateMobile();
    }

    const removeItem = async function (event) {
        if (!event.target.closest(removeElementClass) || !event.target.closest(itemClass)) return;
        let item = event.target.closest(itemClass);

        if (!item.dataset.hasOwnProperty('productKey')) return;
        let response = await apiFetch('Cart', 'remove', {
            key: item.dataset['productKey']
        });

        notice(response.status, response.message);
        update();
    };

    $(document).on('click', removeElementClass, removeItem);

    miniShop2.Callbacks.add('Cart.add.response.success', 'add.miniCartUpdate', update);
    miniShop2.Callbacks.add('Cart.remove.response.success', 'remove.miniCartUpdate', update);
    miniShop2.Callbacks.add('Cart.change.response.success', 'change.miniCartUpdate', update);
    miniShop2.Callbacks.add('Cart.clean.response.success', 'clean.miniCartUpdate', update);
    //endregion
})(jQuery);

async function getProductPreview(id, previewType = 'preview', init = false) {
    const $modal = $('#quickView');
    const $modalBody = $modal.find('.modal-body');

    const initSliders = () => {
        $modal.find('.fotorama').fotorama({...fotoramaDefaults, height: 400});
    };

    const destroySliders = () => {
        const fotorama = $modal.find('.fotorama').fotorama().detach();
    };

    if (init === true) {
        $modal.on('hidden.bs.modal', e => {
            $modalBody.html('');
            destroySliders();
        });
        return;
    }

    if (!id) throw new Error('id required');

    const data = {id: id, previewType: previewType};
    const productData = await apiFetch('Product', 'getPreview', data);
    
    if ('success' !== productData.status) {
        notice(productData.status, productData.message);
        throw new Error(productData.message);
    }
    $modalBody.html(productData.data.body);
    $modal.modal('show');
    initSliders();
    initCountdown();
}

//Products
(function () {
    if ($('#msGallery').length) {
        $('.fotorama').fotorama(fotoramaDefaults);
    }
    
    $(document).on('click', '.quantity .add, .quantity .sub', (e) => {
        const $el = $(e.target);
        const $btn = $el.closest('.sub').add($el.closest('.add')).eq(0);
        const $qtyInput = $el.closest('.ms2_form').find('input[name="count"]');

        let currQty = parseInt($qtyInput.val());
        currQty = $btn.hasClass('add') ? currQty + 1 : currQty - 1;
        if (currQty < 1) currQty = 1;
        $qtyInput.val(currQty);
        $qtyInput.trigger('change');
    });

    getProductPreview(null, '', true);

    const previewBtn = '.product-preview--js';
    const preview = event => {
        const $btn = $(event.target).closest(previewBtn);
        const id = $btn.data('id');
        getProductPreview(id, 'preview');
    };
    $(document).on('click', previewBtn, preview);

    const oneClickBuyBtn = '.product-oneClickBuy--js';
    const oneClickBuy = async event => {
        event.preventDefault();
        const $btn = $(event.target).closest(oneClickBuyBtn);
        const id = $btn.data('id');
        await getProductPreview(id, 'oneClickBuy');
        $('input[name="phone"]').mask('+7 (000) 000-00-00');
    };
    $(document).on('click', oneClickBuyBtn, oneClickBuy);

    const foundCheaperBtn = '.product-foundCheaper--js';
    const foundCheaper = async event => {
        event.preventDefault();
        const $btn = $(event.target).closest(foundCheaperBtn);
        const id = $btn.data('id');
        await getProductPreview(id, 'foundCheaper');
        $('input[name="phone"]').mask('+7 (000) 000-00-00');
    }; 
    $(document).on('click', foundCheaperBtn, foundCheaper);
    
    initCountdown();
})();


async function getModalPreview(id, previewType = 'preview', init = false) {
    const $modal = $('#quickView');
    const $modalBody = $modal.find('.modal-body');

    if (init === true) {
        $modal.on('hidden.bs.modal', e => {
            $modalBody.html('');
        });
        return;
    }

    const data = {id: id, previewType: previewType};
    const productData = await apiFetch('Product', 'getPreview', data);

    if ('success' !== productData.status) {
        notice(productData.status, productData.message);
        throw new Error(productData.message);
    }
    $modalBody.html(productData.data.body);
    $modal.modal('show');
}

//Tinkoff credit
(function(){
    getModalPreview(null, '', true);

    const previewBtncredit1 = '.product-credit1--js';
    const previewcredit1 = event => {
        const $btn = $(event.target).closest(previewBtncredit1);
        const id = $btn.data('id');
        getModalPreview(id,'credit1');
    };
    $(document).on('click', previewBtncredit1, previewcredit1);

    const previewBtncredit2 = '.product-credit2--js';
    const previewcredit2 = event => {
        const $btn = $(event.target).closest(previewBtncredit2);
        const id = $btn.data('id');
        getModalPreview(id,'credit2');
    };
    $(document).on('click', previewBtncredit2, previewcredit2);
})();

//Sitemap
(function () {
    const $map = $('.html-sitemap');

    $map.on('click', '.expand', el => {
        let $el = $(el.target);
        let $current = $el.closest('li');
        let $icon = $el.closest('.expand').find('i');

        if (!$current.hasClass('expanded')) {
            $icon.removeClass('fa-plus').addClass('fa-minus');
            $current.children('ul')
                .stop(true, true)
                .slideDown(500, () => {
                    $current.addClass('expanded')
                });
        } else {
            $icon.add($current.find('i'))
                .removeClass('fa-minus')
                .addClass('fa-plus');
            $current.find('ul')
                .stop(true, true)
                .slideUp(500, () => {
                    $current.removeClass('expanded');
                });
        }

    });
})();

//City Detect
// (function () {
//     if (window.getCookie('city')) return;
//
//     navigator.geolocation.getCurrentPosition((position) => {
//         let curLat = position.coords.latitude;
//         let curLon = position.coords.longitude;
//         let match = navigator.geolocation.cities.reduce(function (prev, curr) {
//             return (
//                 (
//                     Math.abs(curr.lat - curLat) < Math.abs(prev.lat - curLat)
//                     &&
//                     Math.abs(curr.lon - curLon) < Math.abs(prev.lon - curLon)
//                 )
//                     ? curr : prev
//             );
//         });
//
//         if (match.key) {
//             notice('info', `
//                 Ваш город ${match.name}? <span class="btn btn-sm btn-primary js--city--accept"><i class="fa fa-check"></i></span>
//             `, 9999999);
//             $(document).on('click', '.js--city--accept', e => {
//                 setCookie('city', match.key);
//                 location.hostname = match.host;
//             });
//         } else {
//             notice('error', 'Нам не удалось определить ваш город, выберите подходящий из списка.');
//         }
//     }, () => {
//         if (!getCookie('geo.error.notice')) {
//             notice('error', 'Вы запретили использовать свою геолокацию для определения города, выберите город самостоятельно.');
//             setCookie('geo.error.notice', true);
//         }
//     });
// })();

//Filter
(function () {
    const filterItem = '.shop-sidebar__filter-item--extendable';
    const filterExtendBtn = `${filterItem} .shop-sidebar-title`;
    const content = '.checkbox-items';

    $(document).on('click', filterExtendBtn, evt => {
        const $filter = $(evt.target).closest(filterItem);
        const $icon = $filter.find('i');
        const $content = $filter.find(content);

        $content.slideToggle();
        $icon.toggleClass('fa-plus').toggleClass('fa-minus');
    });

    //Filter mobile
    const expand = '.shop-filter--expand';
    const $FilterContainer = $('.shop-filter__container');
    const $Filter = $('.shop-filter');

    const hide = () => {
        if(!$FilterContainer.length || !$Filter.length) return;
        const w = $Filter[0].scrollWidth;
        $FilterContainer[0].style.left = `-${w}px`;
        $Filter.removeClass('opened');
    };
    const show = () => {
        $FilterContainer[0].style.left = null;
        $Filter.addClass('opened');
    };

    if (window.innerWidth <= 991) hide();

    $(document).on('resize', () => {
        if (!$Filter.hasClass('opened') && window.innerWidth <= 991) hide();
    })

    $(document).on('click', expand, () => {
        $Filter.hasClass('opened') ? hide() : show();
    });
})();